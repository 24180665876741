<template>
<!-- 项目板块 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/2.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[3].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[3].childrens[0].name") }}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span>{{item.name}}</span><span v-if="item.childrens&&item.childrens.length>0"  :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              <dl v-if="item.childrens && item.id==checkId && item.childrens.length>0">
                <dd :class="[item2.id==checkChildrenId?'in':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="checknavchildren(item,item2,index2)">{{item2.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[3].childrens[0].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-cooperate">
              <div class="project-module">
                    <div class="project-module-item" v-for="(item,index) in list" :key="index">
                            <div class="tt" v-if="$i18n.locale=='zh'">
                              <img :src="item.imgSrc">
                              <div class="name">{{item.modeNameZh}}</div>
                            </div>
                            <div class="ii" v-if="$i18n.locale=='zh'" v-html="item.modeIntroductionZh">
                            </div>
                            <div class="tt" v-if="$i18n.locale=='en'">
                              <img :src="item.imgSrc">
                              <div class="name">{{item.modeNameEn}}</div>
                            </div>
                            <div class="ii" v-if="$i18n.locale=='en'" v-html="item.modeIntroductionEn">
                            </div>
                    </div>
              </div>
              <div class="module-line" style="margin-top:50px;"></div>
              <div v-for="(item,index) in list" :key="index">
                <div class="module-line" v-if="index==(list.length-1)"></div>
                <div :id="'nuox-project-'+index"></div><!-- 锚点 -->
                <div  v-if="$i18n.locale=='zh'&&item.modeNameViceZh!=''" class="nuox-title-two" style="margin-top:50px;margin-bottom:25px" id="nuox-project-1">
                    <i class="iconfont icon-shuxian"></i>{{item.modeNameViceZh}}<i class="iconfont icon-shuxian"></i>
                </div>
                <div  v-if="$i18n.locale=='zh'" v-html="item.modeContactZh"></div>
                <div  v-if="$i18n.locale=='en'&&item.modeNameViceZh!=''" class="nuox-title-two" style="margin-top:50px;margin-bottom:25px" id="nuox-project-1">
                    <i class="iconfont icon-shuxian"></i>{{item.modeNameViceEn}}<i class="iconfont icon-shuxian"></i>
                </div>
                <div  v-if="$i18n.locale=='en'" v-html="item.modeContactEn"></div>
              </div>
          </div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[3].childrens');
    }
  },
  data:function() {
    return {
        checkId:31,
        checkChildrenId:0,
        list:[]
    };
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){//查询项目板块
      var that = this;
        var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_mode?pageNum=1&pageCount=5&modeType=xmmk"
      );
      if(res.code==0){
        var data = res.data.data;
        var list =[];
        for(var key in data){
          var item = {};
          var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
          item.imgSrc = imgSrc;
          item.modeNameZh = data[key].modeNameZh;
          item.modeNameEn = data[key].modeNameEn;
          item.modeNameViceZh = data[key].modeNameViceZh;
          item.modeNameViceEn = data[key].modeNameViceEn;
          item.modeIntroductionZh = data[key].modeIntroductionZh;
          item.modeIntroductionEn = data[key].modeIntroductionEn;
          item.modeContactZh = data[key].modeContactZh;
          item.modeContactEn = data[key].modeContactEn;
          list.push(item);
        }
        that.list=list;
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2,index){
      document.getElementById("nuox-project-"+index).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },
  }
}
</script>
<style>
@import '../../assets/css/about.css';
.project-module{
  margin-bottom: 20px 0;
  height: 250px;
}
.project-module-item{
  height: 250px;
  width: calc(20% - 10px);
  padding: 5px;
  float: left;
}
.project-module-item .tt{
  position:relative;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #00448F;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  font-size: 16px;
  letter-spacing: 2px;
}
.project-module-item .name{
  position:absolute;
  left: 0;
  width: 100%;
}
.project-module-item .tt img{
  height: 100%;
  width: 100%;
}
.nuox-on-line{
  display: inline-block;
  margin: 0 auto;
}
.project-module-item .ii{
      height: 55%;
      display: flex;
      background-color: #00448F;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 11px;
      line-height: 18px;
}
.nuox-table-two{
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 0px;
}
.nuox-table-two th{
  background-color:#25C8CC;
  height: 25px;
  line-height: 25px;
  color: #fff;
  text-align: center;
}
.nuox-table-two td{
  height: 25px;
  line-height: 25px;
  border: 1px solid #E7EEF4;
  padding: 0 20px;
  font-size: 11px;
}
.nuox-no{
  display: inline-block;
  text-align: center;
  width: 12px;
  height: 12px;
  line-height: 12px;
  border-radius: 50%;
  border: 1px solid #666;
  margin-right: 10px;
}
.yy-project-right{
  height: 100px;
  text-align: center;
  width: 255px;
  float: right;
}
.yy-project-left{
  height: 100px;
  text-align: center;
  width: 425px;
  float: left;
}
.nuox-round-module{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #00448F;
  color: #fff;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 5px;
  font-size: 12px;
}
.yy-info{
  color: #00448F;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
}
.yy-shuxian{
  width: 2px;
  height: 50px;
  background-color: #E7EEF4;
  margin-top: 20px;
  margin-left: calc(50% - 1px);
}
.nuox-kk{
  margin-bottom: 50px;
}
.nuox-kk .grid-content{
  margin: 2px;
  border: 1px solid #EBEBEB;
  height: 150px;
  text-align: center;
  font-size: 13px;
}

.nuox-kk .text-title{
  height: 50%;
  position: relative;
}
.nuox-kk .text-tt{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
}
.nuox-kk .text-line{
  margin: 0 auto;
  width: 20px;
  height: 4px;
  margin-top: 15px;
}
.nuox-kk span{
  margin: 5px;
}
.nuox-project-top{
  margin-top: 10px;
}
.text-gran{
  color: #00448F;
  background-color: #E7EEF4;
}
.nuox-no-two{
  font-size: 20px;
  position: absolute;
  left: 0;
  top:0;
  padding: 10px;
  font-family: "Microsoft YaHei";
  font-weight: 400;
}
.zsc-model-div{
  text-align: center;
}
.zsc-inline{
  display: inline-block;
  margin: 0px 50px;
}
.zsc-model{
  height: 190px;
  display: flex;
   align-items: center;
      justify-content: center;
      text-align: center;
     color: #00448F;
  text-align: center;
  color: #00448F;
  width: 150px;
  float: left;
}
.zsc-model .no{
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
.zsc-model .name{
  font-family: "Microsoft YaHei";
  font-weight: bold;
   font-size: 14px;
   line-height: 20px;
}
.zsc-model .xian{
    margin: 5px auto;
    width: 20px;
    height: 4px;
    background-color: #00448F;

}
.zsc-model .con{
  margin: 10px;
  font-size: 12px;
  line-height: 20px;
}

</style>